<template>
  <the-table-page
    :titleTable="$t('Правила политики')"
    :tableData="tableData"
    :load="load"
    fieldId="policyRuleId"
    :updateData="updateData"
    :fieldInfoList="fieldInfoList"
    :searchStart="searchStart"
    :sortData="sortData"
    :setSort="setSort"
    :deleteEl="deleteEl"
    :prefixEdit="`/corpPolicy/${$route.params.PolicyId}/policyRule`"
    :prefixShow="`/corpPolicy/${$route.params.PolicyId}/policyRule`"
    :linkCreate="`/corpPolicy/${$route.params.PolicyId}/policyRule/create`"
    :customLinkEditShow="customLinkEditShow"
    configName="policyRule"
    :roleName="nameRoleEntity"
    :forceDelete="false"
    :forceIsCreate="false"
    :forsePaginate="false"
    :forsePerpage="false"
  ></the-table-page>
</template>
<script>
import TheTablePage from "../../../TheTablePage.vue";
import { POLITY_RULE, ENTITY_NAME } from "../store/type";

import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_SEARCH_ENTITY
} from "../../../../../src/store/type";

import { READ, DELETE } from "../../../../store/type";
import { mapState } from "vuex";
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
import {
  DEFAULT_PER_PAGE,
  TYPE_ERRORS,
  FIELD_LANG_POLICY_RULE,
  GET_MEMORY_PER_PAGE
} from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import { NAME_OBJECT } from "../../../../RoleConstanans";
export default {
  name: "PolicyRule",
  components: {
    TheTablePage
  },
  data() {
    return {
      load: false
    };
  },
  computed: {
    ...mapState({
      fieldInfoList: state => state.corpPolity.fieldInfoList,
      lang: state => state.common.lang,
      sortData: state => state.pagination.sortData
    }),
    nameRoleEntity() {
      return NAME_OBJECT.policyRule;
    },
    tableData() {
      let langNow = this.$store.state.common.lang;
      let dataNow = this.$store.state.corpPolity.data || [];
      if (dataNow && dataNow.length > 0) {
        dataNow = convertLangDataToTable(
          dataNow,
          langNow,
          FIELD_LANG_POLICY_RULE
        );
        dataNow = dataNow.map(item => {
          return {
            ...item,
            isActive: item.isActive ? this.$t("Да") : this.$t("Нет"),
            isExist: item.isExist ? this.$t("Да") : this.$t("Нет")
          };
        });
        return dataNow;
      }
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  methods: {
    customLinkEditShow(link, row) {
      return `${link}?ruleId=${row["ruleId"]}`;
    },
    getData() {
      this.load = true;
      this.$store
        .dispatch(POLITY_RULE + READ, {
          self: this,
          PolicyId: this.$route.params.PolicyId
        })
        .then(res => {
          this.load = false;
        })
        .catch(err => {
          this.load = false;
          notify(this, this.$t("Ошибка получения данных"), TYPE_ERRORS.DANGER);
        });
    },
    updateData() {
      this.getData();
    },
    searchStart(search) {
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, search);
      this.getData();
    },
    setSort(val) {
      this.$store.commit(SET_MAIN_SORT_ENTITY, val);
      this.getData();
    },
    deleteEl(id) {
      this.$store
        .dispatch(POLITY_RULE + DELETE, {
          PolicyId: this.$route.params.PolicyId,
          id: id,
          self: this
        })
        .then(res => {
          this.getData();
          if (res.errors && res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
          }
        });
    },
    initData() {
      this.$store.commit(ENTITY_NAME + READ, { data: [], fieldInfoList: [] });
      this.$store.commit(SET_MAIN_SORT_ENTITY, {});
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, "");
      this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE());
      this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1);
      this.getData();
    }
  },
  created() {
    this.initData();
  }
};
</script>
