<template>
  <card v-if="$route.params.PolicyId">
    <el-tabs :tab-position="tabPosition" v-model="nowTab">
      <el-tab-pane :label="$t('Правила политики')" name="ruleP">
        <PolicyRule v-if="nowTab=='ruleP'"/>
      </el-tab-pane>
      <el-tab-pane :label="$t('Атрибуты')"  name="attr">
        <AttrValueTable v-if="nowTab=='attr'" routeField="PolicyId" 
        :prefixEditIn="`/corpPolicy/${$route.params.PolicyId}`"
        
        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
  import PolicyRule from "../../../modules/corpPolicy/policyRule/PolicyRule.vue"
  import AttrValueTable from "../attrValue/AttrValueTable"
  import {SET_TAB_POLICY} from "./store/type"
  import {
    Tabs,
    TabPane,
  } from 'element-ui';
 
  export default {
    name: 'PersonData',
    components: {
      ElTabs: Tabs,
      ElTabPane: TabPane,
      PolicyRule,
      AttrValueTable
    },
    data() {
      return {
        tabPosition: "top",
      };
    },
    computed: {
       nowTab: {
      set(tab) {
        this.$store.commit(SET_TAB_POLICY, tab);
      },
      get() {
        return this.$store.state.corpPolity.tabnow;
      }
    }
    }
  }
</script>
